/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cef00c2a-6adf-4dc2-ba6a-f695a633d0a6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8hwMk5Mt0",
    "aws_user_pools_web_client_id": "6k8ni8sbg0pdmoniffqeb8k49f",
    "oauth": {
        "domain": "excitingtheoryf32fdcec-f32fdcec-main.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.excitinghteory.com/app/profile/,http://localhost:8000/app/profile/",
        "redirectSignOut": "https://www.excitinghteory.com/,http://localhost:8000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://rb39eklvx5.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://xxmlvfdzk5bzzdfgtofmuxbcry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;
