import videojs from 'video.js'
import * as React from 'react'
import awsconfig from '../aws-exports';
import { getVodAsset } from '../graphql/queries';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import './index.css'
import {
  Auth,
  Amplify
} from 'aws-amplify';
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Button,
  Grid,
  Typography,
  Container
} from '@material-ui/core';
// import LazyHydrate from "react-lazy-hydration";

Amplify.configure(awsconfig);


// const Inside = (props) => {
//   // console.log('inside.props', props)
//   const { sources, token, displayingMovie, 
//     // prefersDarkMode 
//   } = props
//   return ()
// }

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => Auth.currentCredentials(),
  },
});


class VideoPlayer2 extends React.Component {

  componentDidMount() {
    
    videojs.Vhs.xhr.beforeRequest = function (options) {
      options.uri = `${options.uri}${videojs.getAllPlayers()[0].options().token}`;
      return options;
    };
    this.player = videojs(this.videoNode, this.props);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {

    const { base64 } = this.props

      return (<div data-vjs-player >
      <video ref={(node) => { this.videoNode = node; }} className="video-js" preload="none" poster={base64} />

    </div>
      )

  }
}


class IndexPage extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      sources: [],
      displayingMovie: false,
      chosenItem: {},
      token: null,
    }
  }

  async componentDidMount() {
    const Id = "04ea666a-a520-49fd-8281-8f84ec9d6ffc";
    const vodAsset = await client.query({
      query: getVodAsset,
      variables: {
        id: Id
      },
    }).then((response, error) => {
      // console.log('graphql then')
      if (response.data.getVodAsset.title) {
        this.setState({
          sources: [{
            src: `https://d3e84xexe0tczw.cloudfront.net/${response.data.getVodAsset.video.id}/${response.data.getVodAsset.video.id}.m3u8`,
            type: 'application/x-mpegURL',
          }],
          displayingMovie: true,
          chosenItem: response.data.getVodAsset,
          token: response.data.getVodAsset.video.token,
        });
      }
    })
  }

  render() {
    const { sources, token, displayingMovie } = this.state;

    return (<Layout>

        <StaticQuery
          query={graphql`
            query {
              file(relativePath: { eq: "images/first-frame-of-pulsing-dots.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={data => {

            if (displayingMovie) {
              return (
                <VideoPlayer2
                  controls
                  sources={sources}
                  bigPlayButton={false}
                  muted
                  autoplay
                  token={token}
                  fullscreenToggle={false}
                  playbackRateMenuButton={false}
                  controlBar={false}
                  textTrackSettings={false}
                  errorDisplay={false}
                  displayingMovie={displayingMovie}
                  base64={data.file.childImageSharp.fluid.base64}
                />

              )
            }
            return (<img src={data.file.childImageSharp.fluid.base64} className='parallax' />)
          }
          }
          />

      <Container>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          BE HEARD
          </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Do interesting things.
          </Typography>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              component='a'
              // to='/contact'
              href="mailto:colin@excitingtheory.com"
            >Contact Us</Button>

          </Grid>
        </Grid>
      </Container>

        <div className='iconwrap'><div className='iconspace'></div></div>
    </Layout>)
  }
}

export default IndexPage
